import Vue from 'vue'
import VueRouter from 'vue-router'
import Auth from '../components/Auth.vue'
import Chat from '../components/Chat.vue'
import Images from '../components/Images.vue'
import History_ from '../components/History.vue'
import Search from '../components/Search.vue'
import Gifs from '../components/Gifs.vue'
import store from '../store'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '*',
      redirect: '/auth'
    },
    {
      path: '/',
      redirect: '/auth'
    },
    {
      path: '/auth',
      component: Auth
    },
    {
      path: '/chat',
      component: Chat,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/gifs',
      component: Gifs,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/images',
      component: Images,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/history',
      component: History_,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/search',
      component: Search,
      meta: {
        requiresAuth: true
      }
    }
  ]
})

router.beforeEach((to, from, next) => {
  const meLoggedIn = store.state.myId
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)

  if (requiresAuth && !meLoggedIn) {
    next('/auth')
  } else {
    next()
  }
})

export default router
