<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
html.has-navbar-fixed-top {
  padding-top: 5rem;
}
</style>
