<template>
  <div id="app">
    <div id="login">
      <div class="form-group">
        <input
          v-model="email"
          class="input"
          type="email"
          placeholder="Email"
        >
        <input
          v-model="password"
          class="input"
          type="password"
          placeholder="Password"
        >
        <button
          class="btn btn-info btn-block"
          @click="login()"
        >
          Login with Email
        </button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Auth',

  data() {
    return {
      email: undefined,
      password: undefined
    }
  },

  created() {
    this.$store.dispatch('logMeOut')
  },

  methods: {
    login() {
      this.$store.dispatch('logMeIn', { email: this.email, password: this.password }).then(() => {
        this.$router.replace('/chat')
      })
    }
  }
}
</script>
