<template>
  <div>
    <nav
      class="navbar is-fixed-top"
      :class="connected ? '' : 'is-light'"
      role="navigation"
      aria-label="main-navigation"
    >
      <div
        class="container"
        style="padding: 16px;"
      >
        <div class="level is-mobile">
          <div class="level-left">
            <div class="level-item">
              <span
                class="tag"
                :class="hasCategory('P') ? 'is-link' : ''"
                @click="toggleCategory('P')"
              >
                Photos
              </span>
            </div>
            <div class="level-item">
              <span
                class="tag"
                :class="hasCategory('G') ? 'is-link' : ''"
                @click="toggleCategory('G')"
              >
                Gifs
              </span>
            </div>
            <div class="level-item">
              <span
                class="tag"
                :class="hasCategory('V') ? 'is-link' : ''"
                @click="toggleCategory('V')"
              >
                Videos
              </span>
            </div>
          </div>

          <div class="level-right">
            <div class="level-item">
              <a
                class="button"
                :class="connected ? 'is-white' : 'is-light'"
                @click="goHome"
              >
                <span class="icon is-small">
                  <i class="fas fa-home" />
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>

    <div class="container is-fluid">
      <gallery
        :images="images"
        thumb-key="thumbUrl"
        full-key="mediaUrl"
        category-key="category"
      />
    </div>

    <nav class="navbar is-fixed-bottom">
      <div
        class="container"
        style="padding: 16px;"
      >
        <div class="level is-mobile">
          <div class="level-left">
            <div class="level-item">
              <a
                class="button is-primary"
                :disabled="allLoaded"
                @click="loadMore"
              >
                <span class="icon">
                  <i
                    class="fas fa-sync-alt"
                    aria-hidden="true"
                  />
                </span>
                <span>More</span>
              </a>
            </div>
          </div>

          <div class="level-right">
            <div class="level-item">
              <a class="tag is-small is-rounded is-light">
                {{ images.length }}
              </a>
              <a class="tag is-small is-rounded is-info">
                {{ filteredImages.length }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import { db, storage } from '../config'
import { ref, query, orderByChild, startAt, get } from '@firebase/database'
import { ref as storageRef, getDownloadURL } from '@firebase/storage'
import Gallery from './Gallery'

export default {
  name: 'Images',
  components: {
    Gallery
  },

  data() {
    return {
      limit: 0,
      allAssets: [],
      images: [],
      categories: ['P', 'G', 'V']
    }
  },

  computed: {
    connected() {
      return this.$store.state.myConnectionRef
    },

    filteredImages() {
      return this.allAssets.filter(asset => this.categories.includes(asset.category))
    },

    allLoaded() {
      return (this.images.length >= this.filteredImages.length)
    }
  },

  async mounted() {
    const chatRef = ref(db, 'chat')
    const mediaQuery = query(chatRef, orderByChild('mediaUri'), startAt(' '))
    const snapshot = await get(mediaQuery)

    snapshot.forEach(child => {
      const mediaUri = child.val().mediaUri
      const timestamp = child.val().timestamp
      const category = mediaUri[0].toUpperCase()
      const thumbUri = 'thumbs/' + mediaUri
      const mediaUrl = undefined
      const thumbUrl = undefined
      const asset = {
        mediaUri,
        thumbUri,
        timestamp,
        mediaUrl,
        thumbUrl,
        category
      }
      this.allAssets.push(asset)
    })

    this.allAssets = this.allAssets.sort((a, b) => b.timestamp - a.timestamp)
    this.loadMore()
  },

  methods: {
    goHome() {
      this.$router.push('/chat')
    },

    loadMore() {
      this.limit += 20
      this.images = this.filteredImages.slice(0, this.limit).map(asset => {
        if (!asset.mediaUrl) {
          const thumbRef = storageRef(storage, asset.thumbUri)
          getDownloadURL(thumbRef).then(thumbUrl => {
            asset.thumbUrl = thumbUrl
          })
          const mediaRef = storageRef(storage, asset.mediaUri)
          getDownloadURL(mediaRef).then(mediaUrl => {
            asset.mediaUrl = mediaUrl
          })
        }
        return asset
      })
    },

    hasCategory(cat) {
      return this.categories.includes(cat)
    },

    toggleCategory(cat) {
      if (this.hasCategory(cat)) {
        this.categories = this.categories.filter(c => c !== cat)
      } else {
        this.categories.push(cat)
      }
      this.limit = 0
      this.loadMore()
    }
  }
}
</script>
