<template>
  <div>
    <nav
      class="navbar is-fixed-top"
      :class="connected ? '' : 'is-light'"
      role="navigation"
      aria-label="main-navigation"
    >
      <div
        class="container"
        style="padding: 16px;"
      >
        <div class="level is-mobile">
          <div class="level-left" />
          <div class="level-item">
            <div
              class="control has-icons-right"
              :class="isSearching ? 'is-loading' : ''"
            >
              <input
                ref="inputField"
                v-model="text"
                class="input"
                type="text"
                placeholder="Type text here"
                @keyup.enter="search"
              >
              <span
                v-if="!isSearching"
                class="icon is-small is-right is-link"
                @click="search"
              >
                <i class="fas fa-search" />
              </span>
            </div>
          </div>

          <div class="level-right">
            <div class="level-item is-marginless is-size-7">
              <a class="tag is-small is-rounded is-light">
                {{ messages.length }}
              </a>
              <a class="tag is-small is-rounded is-info">
                {{ keys.length }}
              </a>
            </div>

            <div class="level-item">
              <a
                class="button"
                :class="connected ? 'is-white' : 'is-light'"
                @click="goHome()"
              >
                <span class="icon is-small">
                  <i class="fas fa-home" />
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>

    <div class="container is-fluid">
      <div
        v-for="item in messages"
        :key="item.key"
        class="box"
      >
        <div
          class="content"
          @click="navigateTo(item)"
        >
          <p class="is-size-7">
            <strong>{{ item.name }}</strong> <small>{{ item.timestamp | formatDate }}</small>
            <br v-if="!item.isCollapsed">
            <span>{{ item.message }}</span>
          </p>
        </div>
      </div>
    </div>

    <nav class="navbar is-fixed-bottom">
      <div
        class="container"
        style="padding: 16px"
      >
        <div class="level is-mobile">
          <div class="level-left">
            <div class="level-item">
              <a
                class="button is-primary"
                :disabled="messages.length >= keys.length"
                :class="isLoading ? 'is-loading' : ''"
                @click="loadMore()"
              >
                <span class="icon">
                  <i
                    class="fas fa-sync-alt"
                    aria-hidden="true"
                  />
                </span>
                <span>More</span>
              </a>
            </div>
          </div>

          <div class="level-item">
            <div class="select is-info">
              <select
                v-model="searchMode"
                @change="search"
              >
                <option>ANY</option>
                <option>ALL</option>
                <option>EQU</option>
              </select>
            </div>
          </div>

          <div class="level-right" />
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import moment from 'moment'
import { db, functions } from '../config'
import { ref, get, child } from '@firebase/database'
import { httpsCallable } from '@firebase/functions'

export default {
  name: 'Search',

  filters: {
    formatDate(value) {
      return moment.unix(value).locale('fr').calendar()
    }
  },

  data() {
    return {
      text: undefined,
      keys: [],
      messages: [],
      isSearching: false,
      isLoading: false,
      searchMode: 'ALL'
    }
  },

  computed: {
    connected() {
      return this.$store.state.myConnectionRef
    }
  },

  methods: {
    async search() {
      this.$refs.inputField.blur()
      this.isSearching = true
      const result = await httpsCallable(functions, 'textSearch')({
        query: this.text,
        mode: this.searchMode
      })
      this.messages = []
      this.keys = result.data
      await this.loadMore()
      this.isSearching = false
    },

    async loadMore() {
      this.isLoading = true
      const startIndex = this.messages.length
      const endIndex = startIndex + 25
      const newKeys = this.keys.slice(startIndex, endIndex)
      const chatRef = ref(db, 'chat')
      const snapshots = await Promise.all(newKeys.map(key => {
        const keyRef = child(chatRef, key)
        const snapshot = get(keyRef)
        return snapshot
      }))
      this.messages.push(...snapshots.map(snapshot => {
        const item = snapshot.val()
        item.key = snapshot.key
        return item
      }))
      this.isLoading = false
    },

    navigateTo(item) {
      const startDate = new Date(item.timestamp * 1000)
      startDate.setHours(startDate.getHours() - 12)
      const endDate = new Date(item.timestamp * 1000)
      endDate.setHours(endDate.getHours() + 12)
      this.$router.push({
        path: '/history',
        query: { startDate: startDate.getTime(), endDate: endDate.getTime() }
      })
    },

    goHome() {
      this.$router.push('/chat')
    }
  }
}
</script>

<style scoped>
.container .box {
  margin-left: 1rem;
  margin-right: 1rem;
}

.box p>span {
  white-space: pre-line;
}

</style>
