/* eslint-disable import/no-duplicates */
/* eslint-disable import/first */
if (process.env.NODE_ENV === 'development') {
  self.FIREBASE_APPCHECK_DEBUG_TOKEN = process.env.VUE_APP_DEBUG_TOKEN
}
import { initializeApp } from '@firebase/app'
import { getDatabase, connectDatabaseEmulator } from '@firebase/database'
import { getStorage, connectStorageEmulator } from '@firebase/storage'
import { getAuth, connectAuthEmulator } from '@firebase/auth'
import { getFunctions, connectFunctionsEmulator } from '@firebase/functions'
import { initializeAppCheck, ReCaptchaV3Provider } from '@firebase/app-check'

const firebaseConfig = {
  apiKey: 'AIzaSyCOGGJuv8BHk85W6cc3iixa9suYjBm8SYc',
  authDomain: 'test-199f8.firebaseapp.com',
  databaseURL: 'https://test-199f8.firebaseio.com',
  projectId: 'test-199f8',
  storageBucket: 'test-199f8.appspot.com',
  messagingSenderId: '312152604834',
  appId: '1:312152604834:web:e91f1fdd98324e132357fb'
}

const firebaseApp = initializeApp(firebaseConfig)

initializeAppCheck(firebaseApp, {
  provider: new ReCaptchaV3Provider('6LdcizgcAAAAAIgp0C5pl4OvWeiF702UTU5b2nSw'),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true
})

const db = getDatabase(firebaseApp)
const storage = getStorage(firebaseApp)
const auth = getAuth(firebaseApp)
const functions = getFunctions(firebaseApp)

if (process.env.VUE_APP_EMULATORS === 'yes') {
  connectAuthEmulator(auth, 'http://localhost:9099')
  connectFunctionsEmulator(functions, 'localhost', 5001)
  connectDatabaseEmulator(db, 'localhost', 9000)
  connectStorageEmulator(storage, 'localhost', 9199)
}

export { db, storage, auth, functions }
