<template>
  <div>
    <div
      v-for="line in lines"
      :key="line"
      class="columns is-mobile"
    >
      <div
        v-for="col in 4"
        v-if="index(line, col) < images.length"
        :key="col"
        class="column is-one-fourth"
        :class="selectable && (index(line, col) === selectedIndex) ? 'has-background-primary' : ''"
        @click="openModal(line, col)"
      >
        <figure
          class="image is-square"
          style="object-fit:cover;"
        >
          <img :src="images[index(line, col)][thumbKey]">
        </figure>
      </div>
      <div
        v-else
        class="column is-one-fourth"
      />
    </div>

    <div
      v-if="showModal"
      class="modal is-active"
    >
      <div class="modal-background" />
      <div class="modal-content">
        <video
          v-if="images[modalIndex][categoryKey] === 'V'"
          preload="metadata"
          controls
          :src="images[modalIndex][fullKey]"
        />
        <figure
          v-else
          class="image"
        >
          <img :src="images[modalIndex][fullKey]">
        </figure>
        <div class="level is-mobile">
          <div class="level-item">
            <p
              v-if="modalIndex > 0"
              class="has-text-white-bis has-text-centered is-size-2"
              @click="navigate(-1)"
            >
              &lt;
            </p>
          </div>
          <div
            v-if="selectable"
            class="level-item"
          >
            <a
              v-if="modalIndex !== selectedIndex"
              class="button is-primary"
              @click="select(modalIndex)"
            >
              <span class="icon">
                <i class="fas fa-check" />
              </span>
              <span>Select</span>
            </a>
            <a
              v-else
              class="button is-warning"
              @click="select(modalIndex)"
            >
              <span class="icon">
                <i class="fas fa-times" />
              </span>
              <span>Unselect</span>
            </a>
          </div>
          <div class="level-item">
            <p
              v-if="modalIndex < images.length - 1"
              class="has-text-white-bis has-text-centered is-size-2"
              @click="navigate(1)"
            >
              &gt;
            </p>
          </div>
        </div>
      </div>
      <button
        class="modal-close is-large"
        aria-label="close"
        @click="closeModal()"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Gallery',

  props: ['images', 'thumbKey', 'fullKey', 'categoryKey', 'selectable'],

  data() {
    return {
      showModal: false,
      modalIndex: undefined,
      selectedIndex: undefined
    }
  },

  computed: {
    lines() {
      return Math.ceil(this.images.length / 4)
    }
  },

  methods: {
    index(line, col) {
      return (line - 1) * 4 + col - 1
    },

    openModal(line, col) {
      const index = this.index(line, col)
      this.modalIndex = index
      this.showModal = true
    },

    closeModal() {
      this.modalIndex = undefined
      this.showModal = false
    },

    navigate(step) {
      this.modalIndex += step
    },

    select(modalIndex) {
      if (this.selectedIndex === modalIndex) {
        this.selectedIndex = undefined
      } else {
        this.selectedIndex = modalIndex
      }
      this.$emit('onSelected', this.selectedIndex)
    }
  }
}
</script>
